import { Navigate } from "react-router-dom";
import { adminPayrollLoader } from "~/hooks/admin/queries/use-admin-payroll";
import { alertGroupLoader } from "~/hooks/admin/queries/use-alert-group";
import { companyLoader } from "~/hooks/admin/queries/use-company";
import { employeeAdminLoader } from "~/hooks/admin/queries/use-employee-admin";
import { adminRolloverRequestDetailsLoader } from "~/hooks/dashboards/queries/use-admin-rollover-request-details";
import { distributionRequestLoader } from "~/hooks/admin/queries/use-distribution-request";
import SuperAdminDashboard from "~/views/admin";
import ErrorBoundary from "./error-boundary";
import { schemaLoader } from "~/hooks/dashboards/queries/use-schema-2";
import i18n from "~/i18n";
import { adminOrderLoader } from "~/components/super-admin/pages/orders/api";

export const getAdminRoutes = (_subdomain, t, queryClient) => {
  return [
    {
      loader: async () => {
        await i18n.loadNamespaces(["admin"]);
        return null;
      },
      children: [
        {
          element: <SuperAdminDashboard />,
          errorElement: <ErrorBoundary redirectUnAuthorized />,
          children: [
            {
              path: "/",
              async lazy() {
                let { HomePage } = await import("~/views/admin-views");
                return { Component: HomePage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.dashboard"),
                meta: { title: { key: "breadcrumbs.dashboard" } },
              },
            },
            {
              path: "/alert-groups",
              handle: {
                breadcrumb: t("breadcrumbs.alertGroups.index"),
                meta: { title: { key: "breadcrumbs.alertGroups.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    return {
                      Component: (
                        await import("~/components/super-admin/pages/alert-groups")
                      ).AlertGroupsPage,
                    };
                  },
                },
                {
                  path: ":alertTypeId",
                  loader: alertGroupLoader(queryClient),
                  handle: {
                    breadcrumb: (alertGroup) => alertGroup.alert_type.name,
                    meta: { title: { key: "breadcrumbs.alertGroups.index" } },
                  },
                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/alert-groups/alert-detail"
                        )
                      ).AlertGroupDetailPage,
                    };
                  },
                },
              ],
            },
            {
              path: "/companies",
              handle: {
                breadcrumb: t("breadcrumbs.companies.index"),
                meta: { title: { key: "breadcrumbs.companies.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { CompaniesPage } = await import("~/views/admin-views");
                    return { Component: CompaniesPage };
                  },
                },
                {
                  path: "create",
                  async lazy() {
                    let { CreateCompanyPage } = await import("~/views/admin-views");
                    return { Component: CreateCompanyPage };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.companies.create"),
                    meta: { title: { key: "breadcrumbs.companies.create" } },
                  },
                },
                {
                  path: ":companyUId",
                  loader: companyLoader(queryClient),
                  handle: {
                    breadcrumb: (company) => company.name,
                    meta: { title: (company) => ({ value: company.name }) },
                  },
                  children: [
                    {
                      async lazy() {
                        let { CompanyPage } = await import("~/views/admin-views");
                        return { Component: CompanyPage };
                      },
                      children: [
                        {
                          index: true,
                          element: <Navigate to="company" />,
                        },
                        {
                          path: "company",
                          async lazy() {
                            let { CompanyTab } = await import("~/views/admin-views");
                            return { Component: CompanyTab };
                          },
                        },
                        {
                          path: "investments",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.investments"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.investments" },
                            },
                          },
                          async lazy() {
                            let { InvestmentsTab } = await import(
                              "~/components/super-admin/pages/view-company/investments-tab"
                            );
                            return { Component: InvestmentsTab };
                          },
                        },
                        {
                          path: "documents",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.documents"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.documents" },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/documents-tab"
                                )
                              ).DocumentsTab,
                            };
                          },
                        },
                        {
                          path: "data-exchange",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.importExport"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.importExport" },
                            },
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                return {
                                  Component: (
                                    await import(
                                      "~/components/super-admin/pages/view-company/import-and-export-tab"
                                    )
                                  ).ImportAndExportTab,
                                };
                              },
                            },
                            {
                              path: ":schemaName",
                              loader: async ({ params: { schemaName } }) => {
                                const schema = await schemaLoader(queryClient)();

                                if (!(schemaName in schema.components)) {
                                  throw new Response("Not Found", { status: 404 });
                                }

                                return {
                                  schema,
                                  schemaName,
                                };
                              },
                              handle: {
                                meta: {
                                  title: ({ schema, schemaName }) => ({
                                    value:
                                      schema.components[schemaName]?.["x-human-title"] ||
                                      schema.components[schemaName]?.title,
                                  }),
                                },
                                breadcrumb: ({ schema, schemaName }) => {
                                  return (
                                    schema.components[schemaName]?.["x-human-title"] ||
                                    schema.components[schemaName]?.title
                                  );
                                },
                              },

                              async lazy() {
                                return {
                                  Component: (
                                    await import(
                                      "~/components/super-admin/pages/view-company/import-and-export-tab/form-page"
                                    )
                                  ).ImportAndExportFormPage,
                                };
                              },
                            },
                          ],
                        },
                        {
                          path: "employees",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.employees.index"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.employees.index",
                              },
                            },
                          },
                          lazy: async () => ({
                            Component: (
                              await import(
                                "~/components/super-admin/pages/view-company/employees-tab"
                              )
                            ).EmployeesTab,
                          }),
                        },
                        {
                          path: "payrolls",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.payrolls"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.payrolls" },
                            },
                          },
                          lazy: async () => ({
                            Component: (
                              await import(
                                "~/components/super-admin/pages/view-company/payrolls-tab"
                              )
                            ).PayrollsTab,
                          }),
                        },
                        {
                          path: "admin",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.admins"),
                            meta: { title: { key: "breadcrumbs.companies.tabs.admins" } },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/admins-tab"
                                )
                              ).AdminsTab,
                            };
                          },
                        },
                        {
                          path: "account",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.account"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.account" },
                            },
                          },
                          async lazy() {
                            let { AccountTab } = await import("~/views/admin-views");
                            return { Component: AccountTab };
                          },
                        },
                        {
                          path: "config",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.planConfig"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.planConfig",
                              },
                            },
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { PlanConfig } = await import("~/views/admin-views");
                                return { Component: PlanConfig };
                              },
                            },
                          ],
                        },
                        {
                          path: "custodian",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.custodian"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.custodian" },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/custodian-tab"
                                )
                              ).CustodianTab,
                            };
                          },
                        },
                        {
                          path: "transactions",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.transactions"),
                            meta: {
                              title: { key: "breadcrumbs.companies.tabs.transactions" },
                            },
                          },
                          async lazy() {
                            let { TransactionsTab } = await import("~/views/admin-views");
                            return { Component: TransactionsTab };
                          },
                        },
                        {
                          path: "add-contributions",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.addContributions"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.addContributions",
                              },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/add-contributions-tab"
                                )
                              ).AddContributionsTab,
                            };
                          },
                        },
                        {
                          path: "external-benefits",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.externalBenefits"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.externalBenefits",
                              },
                            },
                          },
                          async lazy() {
                            return {
                              Component: (
                                await import(
                                  "~/components/super-admin/pages/view-company/external-benefits-tab"
                                )
                              ).ExternalBenefitsPage,
                            };
                          },
                        },
                        {
                          path: "custodian",
                          async lazy() {
                            let { Custodian } = await import(
                              "~/components/super-admin/pages/view-company/custodian"
                            );
                            return { Component: Custodian };
                          },
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.custodian.index"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.custodian.index",
                              },
                            },
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { Activity } = await import(
                                  "~/components/super-admin/pages/view-company/custodian/activity"
                                );
                                return { Component: Activity };
                              },
                              // handle: {
                              //   breadcrumb: t(
                              //     "breadcrumbs.companies.tabs.custodian.activity"
                              //   ),
                              //   meta: {
                              //     title: {
                              //       key: "breadcrumbs.companies.tabs.custodian.activity",
                              //     },
                              //   },
                              // },
                            },
                            {
                              path: "positions",
                              async lazy() {
                                let { Positions } = await import(
                                  "~/components/super-admin/pages/view-company/custodian/positions"
                                );
                                return { Component: Positions };
                              },
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.custodian.positions"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.custodian.positions",
                                  },
                                },
                              },
                            },
                          ],
                        },
                        {
                          path: "edit",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.edit"),
                            meta: { title: { key: "breadcrumbs.companies.edit" } },
                          },
                          async lazy() {
                            let { EditCompanyPage } = await import("~/views/admin-views");
                            return { Component: EditCompanyPage };
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { EditCompanyForm } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EditCompanyForm };
                              },
                            },
                            {
                              path: "eligibility",
                              async lazy() {
                                let { EligibilityForm } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EligibilityForm };
                              },
                            },
                            {
                              path: "billing",
                              async lazy() {
                                let { BillingConfigForm } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: BillingConfigForm };
                              },
                            },
                            {
                              path: "addresses",
                              async lazy() {
                                let { EditCompanyAddresses } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EditCompanyAddresses };
                              },
                            },
                          ],
                        },
                      ],
                    },

                    {
                      // separated from the "employees" path above so that the layout is not nested
                      path: "employees",
                      handle: {
                        breadcrumb: t("breadcrumbs.companies.tabs.employees.index"),
                        meta: {
                          title: { key: "breadcrumbs.companies.tabs.employees.index" },
                        },
                      },
                      children: [
                        {
                          path: ":employeeUId",
                          loader: employeeAdminLoader(queryClient),
                          handle: {
                            breadcrumb: (employee) => employee.individual.full_name,
                            meta: {
                              title: (employee) => ({
                                value: employee.individual.full_name,
                              }),
                            },
                          },
                          async lazy() {
                            let { EmployeePage } = await import("~/views/admin-views");
                            return { Component: EmployeePage };
                          },
                          children: [
                            {
                              index: true,
                              async lazy() {
                                let { EmployeeTab } = await import("~/views/admin-views");
                                return { Component: EmployeeTab };
                              },
                            },
                            {
                              path: "edit",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.edit"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.edit",
                                  },
                                },
                              },
                              async lazy() {
                                let { EditEmployeePage } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EditEmployeePage };
                              },
                              children: [
                                {
                                  index: true,
                                  async lazy() {
                                    let { EmployeeForm } = await import(
                                      "~/views/admin-views"
                                    );
                                    return { Component: EmployeeForm };
                                  },
                                },
                                {
                                  path: "addresses",
                                  async lazy() {
                                    let { AddressesForm } = await import(
                                      "~/views/admin-views"
                                    );
                                    return { Component: AddressesForm };
                                  },
                                },
                                {
                                  path: "plan-contributions",
                                  async lazy() {
                                    let { ContributionsForm } = await import(
                                      "~/views/admin-views"
                                    );
                                    return { Component: ContributionsForm };
                                  },
                                },
                              ],
                            },
                            {
                              path: "plan",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.retirementPlan"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.retirementPlan",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeRetirementPlanTab } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeRetirementPlanTab };
                              },
                            },
                            {
                              path: "account",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.account"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.account",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeAccountTab } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeAccountTab };
                              },
                            },
                            {
                              path: "account/external-statuses",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.externalBenefitStatuses"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.externalBenefitStatuses",
                                  },
                                },
                              },
                              async lazy() {
                                let { ExternalBenefitStatuses } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: ExternalBenefitStatuses };
                              },
                            },
                            {
                              path: "elections",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.elections"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.elections",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeElectionsTab } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeElectionsTab };
                              },
                            },
                            {
                              path: "account/transactions",
                              handle: {
                                breadcrumb: t(
                                  "breadcrumbs.companies.tabs.employees.employee.transactions"
                                ),
                                meta: {
                                  title: {
                                    key: "breadcrumbs.companies.tabs.employees.employee.transactions",
                                  },
                                },
                              },
                              async lazy() {
                                let { EmployeeTransactionsTab } = await import(
                                  "~/views/admin-views"
                                );
                                return { Component: EmployeeTransactionsTab };
                              },
                            },
                          ],
                        },
                      ],
                    },

                    {
                      // separated from the "config" path above so that the layout is not nested
                      path: "config",
                      handle: {
                        breadcrumb: t("breadcrumbs.companies.tabs.planConfig"),
                        meta: {
                          title: {
                            key: "breadcrumbs.companies.tabs.planConfig",
                          },
                        },
                      },
                      children: [
                        {
                          path: "edit",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.editPlanConfig"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.editPlanConfig",
                              },
                            },
                          },
                          async lazy() {
                            let { EditPlanConfig } = await import("~/views/admin-views");
                            return { Component: EditPlanConfig };
                          },
                        },
                        {
                          path: "create",
                          handle: {
                            breadcrumb: t("breadcrumbs.companies.tabs.createPlanConfig"),
                            meta: {
                              title: {
                                key: "breadcrumbs.companies.tabs.createPlanConfig",
                              },
                            },
                          },
                          async lazy() {
                            let { CreatePlanConfig } = await import(
                              "~/views/admin-views"
                            );
                            return { Component: CreatePlanConfig };
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "/financials",
              async lazy() {
                let { FinancialsPage } = await import("~/views/admin-views");
                return { Component: FinancialsPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.financials"),
                meta: { title: { key: "breadcrumbs.financials" } },
              },
            },
            {
              path: "/reports",
              handle: {
                breadcrumb: t("breadcrumbs.reports.index"),
                meta: { title: { key: "breadcrumbs.reports.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { Reports } = await import(
                      "~/components/super-admin/pages/reports"
                    );
                    return { Component: Reports };
                  },
                },
                {
                  path: "create",
                  async lazy() {
                    let { CreateReport } = await import(
                      "~/components/super-admin/pages/reports/create"
                    );
                    return { Component: CreateReport };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.reports.create"),
                    meta: { title: { key: "breadcrumbs.reports.create" } },
                  },
                },
              ],
            },
            {
              path: "/data-exchange",
              handle: {
                breadcrumb: t("breadcrumbs.importExport"),
                meta: { title: { key: "breadcrumbs.importExport" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    return {
                      Component: (
                        await import("~/components/super-admin/pages/import-and-export")
                      ).ImportAndExportPage,
                    };
                  },
                },
                {
                  path: ":schemaName",
                  loader: async ({ params: { schemaName } }) => {
                    const schema = await schemaLoader(queryClient)();

                    if (!(schemaName in schema.components)) {
                      throw new Response("Not Found", { status: 404 });
                    }

                    return {
                      schema,
                      schemaName,
                    };
                  },
                  handle: {
                    meta: {
                      title: ({ schema, schemaName }) => ({
                        value:
                          schema.components[schemaName]?.["x-human-title"] ||
                          schema.components[schemaName]?.title,
                      }),
                    },
                    breadcrumb: ({ schema, schemaName }) => {
                      return (
                        schema.components[schemaName]?.["x-human-title"] ||
                        schema.components[schemaName]?.title
                      );
                    },
                  },

                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/import-and-export/form-page"
                        )
                      ).ImportAndExportFormPage,
                    };
                  },
                },
              ],
            },
            {
              path: "/orders",
              handle: {
                breadcrumb: t("breadcrumbs.orders.index"),
                meta: { title: { key: "breadcrumbs.orders.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { Orders } = await import(
                      "~/components/super-admin/pages/orders/orders"
                    );
                    return { Component: Orders };
                  },
                },
                {
                  path: ":id",
                  loader: adminOrderLoader(queryClient),
                  handle: {
                    meta: {
                      title: (order) => ({
                        key: "breadcrumbs.orders.show",
                        options: { orderUId: order.u_id },
                      }),
                    },
                    breadcrumb: (order) =>
                      t("breadcrumbs.orders.show", {
                        orderUId: order.u_id,
                      }),
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        let { OrderDetails } = await import(
                          "~/components/super-admin/pages/orders/order-details"
                        );
                        return { Component: OrderDetails };
                      },
                    },
                    {
                      path: "activity",
                      handle: {
                        breadcrumb: t("breadcrumbs.orders.matchCustodianActivity"),
                        meta: {
                          title: {
                            key: "breadcrumbs.orders.matchCustodianActivity",
                          },
                        },
                      },
                      async lazy() {
                        let { MatchCustodianActivity } = await import(
                          "~/components/super-admin/pages/orders/match-custodian-activity"
                        );
                        return { Component: MatchCustodianActivity };
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "/jobs",
              async lazy() {
                let { TasksPage } = await import("~/views/admin-views");
                return { Component: TasksPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.jobs"),
                meta: { title: { key: "breadcrumbs.jobs" } },
              },
            },
            {
              path: "/jobs-periodic",
              async lazy() {
                let { TasksPeriodicPage } = await import("~/views/admin-views");
                return { Component: TasksPeriodicPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.jobsPeriodic"),
                meta: { title: { key: "breadcrumbs.jobsPeriodic" } },
              },
            },
            {
              path: "/payrolls",
              handle: {
                breadcrumb: t("breadcrumbs.payrolls.index"),
                meta: { title: { key: "breadcrumbs.payrolls.index" } },
              },

              children: [
                {
                  index: true,
                  async lazy() {
                    return {
                      Component: (await import("~/components/super-admin/payrolls-page"))
                        .PayrollsPage,
                    };
                  },
                },
                {
                  path: ":id",
                  loader: adminPayrollLoader(queryClient),
                  handle: {
                    meta: {
                      title: (payroll) => ({
                        key: "breadcrumbs.payrolls.show",
                        options: { payrollId: payroll.u_id },
                      }),
                    },
                    breadcrumb: (payroll) =>
                      t("breadcrumbs.payrolls.show", {
                        payrollId: payroll.u_id,
                      }),
                  },
                  async lazy() {
                    return {
                      Component: (
                        await import(
                          "~/components/super-admin/pages/payrolls/payroll-details"
                        )
                      ).PayrollDetailsPage,
                    };
                  },
                },
              ],
            },
            {
              path: "/documents/create",
              async lazy() {
                let { UploadDocsPage } = await import("~/views/admin-views");
                return { Component: UploadDocsPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.documents"),
                meta: { title: { key: "breadcrumbs.documents" } },
              },
            },
            {
              path: "/invites",
              children: [
                {
                  index: true,

                  async lazy() {
                    let { InvitesPage } = await import("~/views/admin-views");
                    return { Component: InvitesPage };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.invitations.index"),
                    meta: { title: { key: "breadcrumbs.invitations.index" } },
                  },
                },

                {
                  path: "create",
                  async lazy() {
                    let { CreateInvitePage } = await import("~/views/admin-views");
                    return { Component: CreateInvitePage };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.invitations.create"),
                    meta: { title: { key: "breadcrumbs.invitations.create" } },
                  },
                },
              ],
            },
            {
              path: "/external-activities",
              async lazy() {
                let { ExternalActivities } = await import("~/views/admin-views");
                return { Component: ExternalActivities };
              },
              handle: {
                breadcrumb: t("breadcrumbs.externalActivities"),
                meta: { title: { key: "breadcrumbs.externalActivities" } },
              },
            },
            {
              path: "rmd",
              async lazy() {
                let { RMDPage } = await import("~/views/admin-views");
                return { Component: RMDPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.RMD"),
                meta: { title: { key: "breadcrumbs.RMD" } },
              },
            },
            {
              path: "/rollover-requests",
              handle: {
                breadcrumb: t("breadcrumbs.rolloverRequests.index"),
                meta: { title: { key: "breadcrumbs.rolloverRequests.index" } },
              },
              children: [
                {
                  index: true,
                  async lazy() {
                    let { RolloverRequestsPage } = await import("~/views/admin-views");
                    return { Component: RolloverRequestsPage };
                  },
                },
                {
                  path: ":id",
                  loader: adminRolloverRequestDetailsLoader(queryClient),
                  async lazy() {
                    let { RolloverDetails } = await import("~/views/admin-views");
                    return { Component: RolloverDetails };
                  },
                  handle: {
                    meta: {
                      title: (rolloverDetails) => ({
                        key: "breadcrumbs.rolloverRequests.show",
                        options: {
                          employeeName: rolloverDetails.employee.individual.full_name,
                          companyName: rolloverDetails.employee.company_name,
                        },
                      }),
                    },
                    breadcrumb: (rolloverDetails) =>
                      t("breadcrumbs.rolloverRequests.show", {
                        employeeName: rolloverDetails.employee.individual.full_name,
                        companyName: rolloverDetails.employee.company_name,
                      }),
                  },
                },
                {
                  path: "create",
                  async lazy() {
                    let { CreateRolloverRequest } = await import("~/views/admin-views");
                    return { Component: CreateRolloverRequest };
                  },
                  handle: {
                    breadcrumb: t("breadcrumbs.rolloverRequests.create"),
                    meta: { title: { key: "breadcrumbs.rolloverRequests.create" } },
                  },
                },
              ],
            },
            {
              path: "/remediation",
              handle: {
                breadcrumb: t("breadcrumbs.remediation.index"),
                meta: { title: { key: "breadcrumbs.remediation.index" } },
              },
              async lazy() {
                let { RemediationPage } = await import("~/views/admin-views");
                return { Component: RemediationPage };
              },
              children: [
                {
                  index: true,
                  element: <Navigate to="upload" />,
                },
                {
                  path: "send",
                  handle: {
                    breadcrumb: t("breadcrumbs.remediation.send"),
                    meta: { title: { key: "breadcrumbs.remediation.send" } },
                  },
                  async lazy() {
                    let { RemediationSendPage } = await import("~/views/admin-views");
                    return { Component: RemediationSendPage };
                  },
                },
                {
                  path: "upload",
                  handle: {
                    breadcrumb: t("breadcrumbs.remediation.upload"),
                    meta: { title: { key: "breadcrumbs.remediation.upload" } },
                  },
                  async lazy() {
                    let { RemediationUploadPage } = await import("~/views/admin-views");
                    return { Component: RemediationUploadPage };
                  },
                },
              ],
            },
            {
              path: "/demos",
              async lazy() {
                let { CreateDemoPage } = await import("~/views/admin-views");
                return { Component: CreateDemoPage };
              },
              handle: {
                breadcrumb: t("breadcrumbs.demos"),
                meta: { title: { key: "breadcrumbs.demos" } },
              },
            },
            {
              path: "/distribution-requests",
              handle: {
                breadcrumb: t("breadcrumbs.distributionRequests.index"),
                meta: { title: { key: "breadcrumbs.distributionRequests.index" } },
              },

              children: [
                {
                  index: true,
                  async lazy() {
                    let { DistributionRequestsPage } = await import(
                      "~/components/super-admin/pages/distribution-requests"
                    );
                    return { Component: DistributionRequestsPage };
                  },
                },
                {
                  path: "create",
                  handle: {
                    breadcrumb: t("breadcrumbs.distributionRequests.create"),
                    meta: { title: { key: "breadcrumbs.distributionRequests.create" } },
                  },
                  async lazy() {
                    let { CreateDistributionRequest } = await import(
                      "~/views/admin-views"
                    );
                    return { Component: CreateDistributionRequest };
                  },
                },
                {
                  path: ":id",
                  loader: distributionRequestLoader(queryClient),
                  handle: {
                    meta: {
                      title: (distributionRequest) => ({
                        key: "breadcrumbs.distributionRequests.show",
                        options: {
                          distributionRequestId: distributionRequest.id,
                        },
                      }),
                    },
                    breadcrumb: (distributionRequest) =>
                      t("breadcrumbs.distributionRequests.show", {
                        distributionRequestId: distributionRequest.id,
                      }),
                  },
                  children: [
                    {
                      index: true,
                      async lazy() {
                        return {
                          Component: (
                            await import(
                              "~/components/super-admin/pages/distribution-requests/view-request"
                            )
                          ).ViewDistributionRequestPage,
                        };
                      },
                    },

                    {
                      path: "edit",
                      handle: {
                        breadcrumb: t("breadcrumbs.distributionRequests.edit"),
                        meta: { title: { key: "breadcrumbs.distributionRequests.edit" } },
                      },
                      async lazy() {
                        let { UpdateDistributionRequest } = await import(
                          "~/views/admin-views"
                        );
                        return { Component: UpdateDistributionRequest };
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};
