import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiFetchOptions } from "~/types/openapi-params";

/**
 * Order
 */

interface FetchAdminOrderProps {
  orderUId: string;
  fetchOptions?: OpenApiFetchOptions<"get", "/api/v1/admin/orders/{order_u_id}">;
}

export const fetchAdminOrder = async ({
  orderUId,
  fetchOptions = {},
}: FetchAdminOrderProps) => {
  const { data } = await openapi.get("/api/v1/admin/orders/{order_u_id}", {
    ...fetchOptions,
    params: {
      path: {
        order_u_id: orderUId,
      },
    },
  });

  return data;
};

export const fetchOrderTransactions = async ({
  orderUId,
  fetchOptions = {},
}: FetchAdminOrderProps) => {
  const { data } = await openapi.get("/api/v1/admin/orders/{order_u_id}/transactions", {
    ...fetchOptions,
    params: {
      path: {
        order_u_id: orderUId,
      },
    },
  });

  return data;
};

interface AdminOrderQueryProps {
  orderUId: string;
}

const adminOrderQuery = ({ orderUId }: AdminOrderQueryProps) => ({
  queryKey: ["superuser", "order", { orderUId }],
  queryFn: () => fetchAdminOrder({ orderUId }),
  enabled: !!orderUId,
});

const orderTransactionsQuery = ({ orderUId }: AdminOrderQueryProps) => ({
  queryKey: ["superuser", "order", { orderUId, transactions: 1 }],
  queryFn: () => fetchOrderTransactions({ orderUId }),
  enabled: !!orderUId,
})

export const useAdminOrder = ({ orderUId }: AdminOrderQueryProps) => {
  return useQuery(
    adminOrderQuery({
      orderUId,
    })
  );
};

export const useAdminOrderTransactions = ({ orderUId }: AdminOrderQueryProps) => {
  return useQuery(orderTransactionsQuery({ orderUId }));
};

export const adminOrderLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: { id: string } }) => {
    const query = adminOrderQuery({ orderUId: params.id });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};



