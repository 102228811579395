import React from "react";
import { Toaster } from "react-hot-toast";

function CustomToaster() {
  return (
    <Toaster
      position="top-right"
      containerStyle={{
        top: 48,
        right: 40,
      }}
      reverseOrder={false}
      toastOptions={{
        duration: 3000,
        success: {
          icon: "",
          className: "w-96 bg-[#191914] text-left text-white",
        },
      }}
    />
  );
}

export default CustomToaster;
