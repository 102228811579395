import { FC } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import Sheet from "@mui/joy/Sheet";
import IconButton from "@mui/joy/IconButton";
import { listItemButtonClasses } from "@mui/joy/ListItemButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import ArticleIcon from "@mui/icons-material/Article";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ReportIcon from "@mui/icons-material/Report";
import FolderIcon from "@mui/icons-material/Folder";
import PaymentsIcon from "@mui/icons-material/Payments";
import TaskIcon from "@mui/icons-material/TaskOutlined";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import ViewListIcon from "@mui/icons-material/ViewList";
import PaidIcon from "@mui/icons-material/PaidOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceOutlined";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InsightsIcon from "@mui/icons-material/Insights";

import Logo from "~/components/logo";
import { isProduction } from "~/utils";
import { SidebarListItem } from "~/components/super-admin/layout/sidebar/sidebar-list-item";
import { SidebarNestedList } from "~/components/super-admin/layout/sidebar/sidebar-nested-list";
import { useJoyTheme } from "~/hooks/use-joy-theme";
import { signOut as logOut } from "~/helpers";

const CustodianNestedListItems = () => {
  const { t } = useTranslation();

  return (
    <>
      <SidebarListItem icon={<PaidIcon />} href="/external-activities">
        {t("admin:sidebar.cashActivity")}
      </SidebarListItem>
    </>
  );
};

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}

export const Sidebar: FC<SidebarProps> = ({ onToggle, isOpen }) => {
  const theme = useJoyTheme();
  const { t } = useTranslation();

  return (
    <Sheet
      variant={theme.sidebar.variant}
      color={theme.sidebar.color}
      sx={{
        position: "sticky",
        top: 0,
        transition: "padding 0.4s, width 0.4s",
        zIndex: 20,
        height: "100dvh",
        width: {
          xs: isOpen
            ? `${theme.sidebar.width.open}px`
            : `${theme.sidebar.width.closedXs}px`,
          sm: isOpen
            ? `${theme.sidebar.width.open}px`
            : `${theme.sidebar.width.closed}px`,
        },
        px: {
          xs: isOpen ? 2 : 0.5,
          sm: isOpen ? 2 : 1,
        },
        py: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 2,
          justifyContent: isOpen ? "space-between" : "center",
          pl: isOpen ? 1 : 0,
        }}
      >
        {isOpen && <Logo type="penelope" variant="full" />}
        <IconButton onClick={onToggle}>
          {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            "--List-nestedInsetStart": (theme) => (isOpen ? theme.spacing(1) : 0),
            "--ListItem-radius": (theme) => theme.vars.radius.sm,
          }}
        >
          <SidebarListItem icon={<DashboardIcon />} href="/">
            {t("admin:sidebar.dashboard")}
          </SidebarListItem>

          <SidebarListItem icon={<BusinessIcon />} href="/companies">
            {t("admin:sidebar.companies")}
          </SidebarListItem>

          <SidebarListItem icon={<ReceiptIcon />} href="/payrolls">
            {t("admin:sidebar.payrolls")}
          </SidebarListItem>

          <SidebarListItem icon={<ViewListIcon />} href="/orders">
            {t("admin:sidebar.orders")}
          </SidebarListItem>

          <SidebarListItem icon={<PaymentsIcon />} href="/distribution-requests">
            {t("admin:sidebar.distributionRequests")}
          </SidebarListItem>

          <SidebarListItem icon={<RequestQuoteIcon />} href="/rollover-requests">
            {t("admin:sidebar.rolloverRequests")}
          </SidebarListItem>

          <SidebarListItem icon={<UpdateOutlinedIcon />} href="/rmd">
            {t("admin:sidebar.rmd")}
          </SidebarListItem>

          <SidebarListItem icon={<MedicalServicesIcon />} href="/remediation">
            {t("admin:sidebar.remediation")}
          </SidebarListItem>

          <SidebarListItem icon={<ArticleIcon />} href="/documents/create">
            {t("admin:sidebar.documents")}
          </SidebarListItem>

          <SidebarListItem icon={<EmailIcon />} href="/invites">
            {t("admin:sidebar.invitations")}
          </SidebarListItem>

          {/*<SidebarListItem icon={<ReportIcon />} href="/alert-groups">
            {t("admin:sidebar.alertGroups")}
          </SidebarListItem>*/}

          <Divider />

          {/*<SidebarListItem icon={<InsightsIcon />} href="/financials">
            {t("admin:sidebar.financials")}
          </SidebarListItem>*/}

          <SidebarListItem icon={<FolderIcon />} href="/reports">
            {t("admin:sidebar.reports")}
          </SidebarListItem>

          <SidebarListItem icon={<DownloadOutlinedIcon />} href="/data-exchange">
            {t("admin:sidebar.importExport")}
          </SidebarListItem>

          <Divider />

          {/*<SidebarListItem icon={<WorkIcon />} href="/jobs">
            {t("admin:sidebar.jobs")}
          </SidebarListItem>*/}

          {!isProduction && (
            <SidebarListItem icon={<CoPresentIcon />} href="/demos">
              {t("admin:sidebar.demos")}
            </SidebarListItem>
          )}

          <Divider />

          {/**
           * Custodian list items
           * ----
           * When the sidebar is closed, we hide the "Custodian" button and expand
           * the list so that every item is visible.
           * */}
          {false && isOpen ? (
            <SidebarNestedList
              icon={<AccountBalanceIcon />}
              label={t("admin:sidebar.custodian")}
            >
              <List sx={{ gap: 0.5, mt: 1 }}>
                <CustodianNestedListItems />
              </List>
            </SidebarNestedList>
          ) : (
            <CustodianNestedListItems />
          )}

          <Divider />

          <SidebarListItem icon={<LogoutIcon />} onClick={logOut}>
            {t("admin:sidebar.logOut")}
          </SidebarListItem>
        </List>
      </Box>
    </Sheet>
  );
};
