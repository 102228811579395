import { createTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Outlet, Navigate } from "react-router-dom";
import { Error, Loading } from "~/components";
import { Layout } from "~/components/super-admin/layout";
import ModalContextProvider from "~/components/super-admin/modal-context";
import "~/components/super-admin/styles.css";
import { currentUserSelector, errorSelector } from "~/redux/selectors";
import { usePageTitle } from "~/routes/use-page-title";
import { isDevEnvironment, isProduction, isStaging } from "~/utils";
import DefaultLayout from "~/layouts/default-layout";

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import {
  extendTheme as joyExtendTheme,
  CssVarsProvider as JoyCssVarsProvider,
} from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import { useOriginalUrl } from "~/routes/use-original-url";
import { useTranslation } from "react-i18next";
import { useSchema } from "~/hooks/dashboards/queries/use-schema-2";

let theme = createTheme({
  palette: {
    primary: {
      main: isProduction ? "#163B16" : "#997F37",
    },
    secondary: {
      main: "#141A16",
    },
    danger: {
      main: "#f44336",
    },
    info: {
      main: "#0288d1",
    },
  },
});

theme = createTheme(theme, {
  palette: {
    pending: theme.palette.augmentColor({ color: { main: "#808080" } }),
    progressing: theme.palette.augmentColor({ color: { main: "#1976d2" } }),
    approved: theme.palette.augmentColor({ color: { main: "#4caf50" } }),
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-actionsCell": {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            minWidth: "fit-content",
            gap: "1rem",
            "& *": {
              flexShrink: 0,
            },
          },
        },
      },
    },
  },
});

// This is a stopgap while Joy UI and MUI are both being used
// @see https://mui.com/joy-ui/integrations/material-ui/
const isDevelopment = isDevEnvironment();

const materialTheme = materialExtendTheme(theme);
const joyTheme = joyExtendTheme({
  components: {
    JoyStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
  sidebar: {
    variant: (isDevelopment || isStaging) && "soft",
    color: isDevelopment ? "neutral" : isStaging ? "danger" : null,

    width: {
      open: 240,
      closed: 60,
      closedXs: 50,
    },
  },
});

const ChildrenWrapper = ({ children }) => {
  const { isLoading: isLoadingSchemas } = useSchema();
  if (isLoadingSchemas) {
    return <Loading fullScreen />;
  }
  return children;
};

function SuperAdminDashboard() {
  const { title } = usePageTitle();
  const { i18n } = useTranslation();

  const user = useSelector(currentUserSelector);
  const error = useSelector(errorSelector);
  const { url: loginUrlWithOriginalUrl } = useOriginalUrl({ pathname: "/login" });

  useEffect(() => {
    i18n?.loadNamespaces(["admin"]);
  }, [i18n]);

  if (!isEmpty(error)) {
    return (
      <DefaultLayout showProgressCircle={false}>
        <Error
          code={error.code}
          message={error.message}
          description={error.description}
          canGoHome={false}
        />
      </DefaultLayout>
    );
  }

  if (!user.id) {
    return <Navigate to={loginUrlWithOriginalUrl} />;
  }
  if (!user.is_superuser && ["admin", "owner"].includes(user.role)) {
    return <Navigate to="/company/main" />;
  }
  if (!user.is_superuser && ["user"].includes(user.role)) {
    return <Navigate to="/individual/main" />;
  }
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider theme={joyTheme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ConfirmProvider>
              <ModalContextProvider>
                <Layout title={title}>
                  <ChildrenWrapper>
                    <Suspense fallback={<Loading fullScreen />}>
                      <Outlet />
                    </Suspense>
                  </ChildrenWrapper>
                </Layout>
              </ModalContextProvider>
            </ConfirmProvider>
          </LocalizationProvider>
        </SnackbarProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default SuperAdminDashboard;
