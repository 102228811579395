import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { useState } from "react";
import { usePopper } from "react-popper";
import { Body2, S1 } from "./typography";
import twMerge from "~/utils/tw-merge";

const variants = {
  default: {
    popperClasses:
      "z-[100] w-72 whitespace-normal rounded-2xl border border-secondary-light_1 bg-white p-4",
    arrowClasses: "before:border-secondary-light_1",
    tooltipTitle: "",
    tooltipText: "mt-2",
  },
  green: {
    popperClasses:
      "z-[100] w-72 whitespace-normal rounded-2xl border border-primary-light border-primary-light bg-primary-light text-white p-4",
    arrowClasses: "before:border-primary-light",
    tooltipTitle: "my-2 font-medium text-white",
    tooltipText: "my-2 text-white",
  },
  black: {
    popperClasses:
      "z-[100] w-72 whitespace-normal rounded-lg border border-secondary-light bg-secondary-main text-sm text-white p-2",
    arrowClasses: "before:border-secondary-light",
    tooltipTitle: "font-medium text-white",
    tooltipText: "text-white",
  },
};

export default function Tooltip({
  direction = "top",
  children,
  text,
  className = "",
  style = {},
  popperClassName = "",
  textContainerClassName = "",
  alwaysShowPopper = false,
  closeText = "",
  tooltipTitle = "",
  tooltipText = "",
  disabled = false,
}) {
  const variant = "black";
  const [shouldHide, setShouldHide] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const shouldShowPopper = !shouldHide && (alwaysShowPopper || isVisible);
  const [referenceElement, setReferenceElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "eventListeners", enabled: shouldShowPopper },
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      { name: "arrow", options: { element: arrowElement, padding: 10 } },
      {
        name: "flip",
        enabled: false,
      },
    ],
    placement: direction,
  });

  const canShowPopper = !disabled && (children || tooltipTitle || tooltipText);

  const showPopper = () => canShowPopper && setIsVisible(true);
  const hidePopper = () => setIsVisible(false);

  const renderToolTipContent = () => {
    if (!children && (tooltipTitle || tooltipText)) {
      return (
        <div className="space-y-1">
          {!!tooltipTitle && (
            <S1
              className={variants[variant].tooltipTitle}
              dangerouslySetInnerHTML={{
                __html: tooltipTitle,
              }}
            />
          )}
          <Body2
            dangerouslySetInnerHTML={{
              __html: tooltipText,
            }}
            className={variants[variant].tooltipText}
          />
        </div>
      );
    }
    return children;
  };

  return (
    <div
      className={twMerge("relative inline-block normal-case", className)}
      style={style}
      ref={setReferenceElement}
      onPointerEnter={showPopper}
      onPointerLeave={hidePopper}
      onFocus={showPopper}
      onBlur={hidePopper}
    >
      <span
        className={twMerge(
          "cursor-pointer",
          !alwaysShowPopper && "tooltip-hover-area",
          textContainerClassName
        )}
      >
        {text}
      </span>
      {createPortal(
        <div
          ref={setPopperElement}
          style={styles.popper}
          className={`${variants[variant].popperClasses} ${popperClassName} ${
            !disabled && shouldShowPopper ? "block" : "hidden"
          }`}
          {...attributes.popper}
        >
          {renderToolTipContent()}
          {closeText ? (
            <button className={"font-medium"} onClick={() => setShouldHide(true)}>
              {closeText}
            </button>
          ) : null}
          <div
            id="tooltip-arrow"
            ref={setArrowElement}
            className={`${variants[variant].arrowClasses} before:border-b before:border-r ${direction}`}
            style={styles.arrow}
            data-popper-arrow
          />
        </div>,
        document.querySelector("#tooltips")
      )}
    </div>
  );
}

Tooltip.prototypes = {
  text: PropTypes.node,
  children: PropTypes.node,
  direction: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
  ]),
};
