import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiPathParams } from "~/types/openapi-params";

interface FetchDistributionRequestProps
  extends OpenApiPathParams<
    "get",
    "/api/v1/admin/distribution-requests/{distribution_request_id}"
  > {}

const fetchDistributionRequest = async ({
  distributionRequestId,
}: FetchDistributionRequestProps) => {
  const { data } = await openapi.get(
    "/api/v1/admin/distribution-requests/{distribution_request_id}",
    {
      params: { path: { distribution_request_id: distributionRequestId } },
    }
  );

  return data;
};

const distributionRequestQuery = ({
  distributionRequestId,
}: FetchDistributionRequestProps) => ({
  queryKey: ["admin", "distribution-requests", distributionRequestId],
  queryFn: () => fetchDistributionRequest({ distributionRequestId }),
  useErrorBoundary: true,
});

export const distributionRequestLoader = (queryClient: QueryClient) => {
  return async ({ params }) => {
    const query = distributionRequestQuery({ distributionRequestId: params.id });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useDistributionRequest = ({
  distributionRequestId,
}: FetchDistributionRequestProps) => {
  return useQuery(distributionRequestQuery({ distributionRequestId }));
};
